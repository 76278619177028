import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";
import Tooltip from "@/components/common/Tooltip";
import IconVerified from "@/common/icons/check-mark.svg";

const SIZES = {
  sm: ["h-3 w-3", "h-1.5 w-1.5"],
  base: ["h-4 w-4", "h-2 w-2"],
  lg: ["h-6 w-6", "h-3 w-3"],
};

const ConsultantCardVerified = ({ size = "base" }) => {
  const { t } = useTranslation("common");

  return (
    <Tooltip title={t`consultant-card.verified`}>
      <div className={clsx("flex-center shrink-0 cursor-pointer rounded-full bg-blue", SIZES[size][0])}>
        <IconVerified className={clsx("text-white", SIZES[size][1])} />
      </div>
    </Tooltip>
  );
};

export default ConsultantCardVerified;
