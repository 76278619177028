import { Children, cloneElement, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";
import usePopper from "@/common/hook/usePopper";

const Tooltip = ({ title, children, white = true }) => {
  const [id] = useState(() => `tooltip-${uuidv4()}`);
  const popperRef = useRef(null);
  const { referenceRef, enable, disable, isEnable } = usePopper(popperRef);

  return (
    <>
      <div
        id={id}
        role="tooltip"
        ref={popperRef}
        className={clsx(
          "rounded-lg py-1.5 px-3 text-xs font-semibold",
          white ? "bg-white" : "bg-purple-800",
          !isEnable && "hidden"
        )}
      >
        {title}
      </div>
      {cloneElement(Children.only(children), {
        ref: referenceRef,
        "aria-describedby": id,
        onMouseEnter: enable,
        onFocus: enable,
        onBlur: disable,
        onMouseLeave: disable,
      })}
    </>
  );
};

export default Tooltip;
